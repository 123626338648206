import { CreateControllerFn, IUser } from '@wix/yoshi-flow-editor';
import {
  setConfigGlobally,
  setCurrentUserGlobally,
  wrappedRenderLoginMenus,
} from '../../viewer-app-module';
import { toMonitored } from '../../viewer-app-module/utils/monitoring';
import { setMobileMembersMenuValue } from '../../viewer-app-module/services/menu-renderer-editor';
import { AppData } from '../../viewer-app-module/types';

const createController: CreateControllerFn = async ({
  controllerConfig,
  appData,
  flowAPI,
}) => {
  const {
    wixCodeApi,
    essentials: { httpClient },
  } = controllerConfig;
  const isInEditor = wixCodeApi.window.viewMode === 'Editor';

  setConfigGlobally(controllerConfig);

  wixCodeApi.user.onLogin((loggedInUser: IUser) =>
    toMonitored('onLogin', () =>
      setCurrentUserGlobally(loggedInUser, httpClient).then(() =>
        wrappedRenderLoginMenus(controllerConfig, appData as AppData, flowAPI),
      ),
    )(),
  );

  return {
    async pageReady() {
      if (isInEditor) {
        if (flowAPI.environment.isMobile) {
          setMobileMembersMenuValue(
            $w,
            wixCodeApi,
            (appData as AppData).parsedRouters,
          );
        }
      } else {
        wrappedRenderLoginMenus(controllerConfig, appData as AppData, flowAPI);
      }
    },
  };
};

export default createController;
